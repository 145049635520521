import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Snatch Balance 4×2 `}<em parentName="p">{`(light, work on speed under bar)`}</em></p>
    <p>{`Full Snatch 5×1+1\\@90% 1RM `}<em parentName="p">{`(drop, reset, go)`}</em></p>
    <p>{`then,`}</p>
    <p>{`20-15-10-5 calories/reps:`}</p>
    <p>{`Calorie Row`}</p>
    <p>{`Hang Squat Snatch (95/65)(RX+115/75)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      